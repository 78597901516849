import React from "react";
import { RichText } from "prismic-reactjs";
import tw, { styled } from "twin.macro";
import { H3 } from "../../utils/helpers.js";
import { linkResolver } from "../../utils/linkResolver";

const Section = styled.dl`
  ${tw` max-w-5xl mx-auto`}
  & H3 {
    ${tw`mt-16 mb-0 md:my-4 md:col-span-12`}
  }
`;

const Question = tw.dt`
  text-base leading-6 font-medium text-ink md:col-span-5 md:border-t border-gray-200 pt-12 pb-2 md:py-8 md:pr-8
`;

const Answer = tw.dd`
  mt-2 md:mt-0 md:col-span-7 text-base leading-6 text-gray-800 border-t border-gray-200 py-3 md:py-8
`;

const Row = tw.div`
  md:grid md:grid-cols-12 md:gap-0
`;

const FAQSection = ({ title, questions }) => {
  const section_id = title.replace(/\s/g, "-").toLowerCase();
  // console.log(section_id);
  return (
    <Section id={section_id}>
      <H3>{title}</H3>
      {questions.map(({ question, answer }, idx) => (
        <Row key={`${section_id}-${idx}`}>
          <Question>{question}</Question>
          <Answer>
            <RichText render={answer} linkResolver={linkResolver} />
          </Answer>
        </Row>
      ))}
    </Section>
  );
};

export default FAQSection;
