import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import { RichText } from "prismic-reactjs";
import Layout from "../layouts/layout.js";
import SEO from "../components/Seo";
import tw from "twin.macro";
import Header from "../components/Header.js";
import { H1, H4 } from "../utils/helpers.js";
import FAQSection from "../components/slices/FAQSection";

const PageWrapper = tw.main`
  relative flex flex-col md:flex-row p-2 md:p-12 items-start justify-around
`;

const FAQWrapper = tw.section`
  px-4 md:px-8
`;

const JumpNav = tw.div`
  md:sticky top-12 flex flex-col p-8 bg-gray-50 w-full md:w-64 flex-initial
`;

const JumpLink = tw.a`
  py-2 hover:text-fire underline
`;

const FAQ = ({ data }) => {
  const page = get(data, "prismic.allFaq_pages.edges[0].node");
  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />

      <Header bgImage="https://source.unsplash.com/SVpCSOCcCwA/1600x900">
        <H1>Frequently Asked Questions</H1>
      </Header>

      <PageWrapper>
        <JumpNav>
          <H4 tw="mb-4">Jump to:</H4>
          {page.body.map(({ primary }, idx) => {
            const title = RichText.asText(primary.section_title);
            const section_id = title.replace(/\s/g, "-").toLowerCase();
            return (
              <JumpLink href={`#${section_id}`} key={section_id}>
                {RichText.asText(primary.section_title)}
              </JumpLink>
            );
          })}
        </JumpNav>
        <FAQWrapper>
          {page.body.map(({ primary, fields }, idx) => (
            <FAQSection
              key={`faq-section-${idx}`}
              title={RichText.asText(primary.section_title)}
              questions={fields}
            />
          ))}
        </FAQWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default FAQ;

export const query = graphql`
  query FAQQuery {
    prismic {
      allFaq_pages {
        edges {
          node {
            body {
              ... on PRISMIC_Faq_pageBodyFaq_section {
                type
                label
                primary {
                  section_title
                }
                fields {
                  answer
                  question
                }
              }
            }
            page_title
          }
        }
      }
    }
  }
`;
